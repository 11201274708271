<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo and title -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text v-if="!$router.currentRoute.params.expired">
          <p class="text-2xl font-weight-semibold text--primary mb-2">Restablecer la contraseña</p>
          <p class="mb-2">Su nueva contraseña debe ser diferente de las contraseñas utilizadas anteriormente</p>
        </v-card-text>

        <v-card-text v-else>
          <p class="text-2xl font-weight-semibold text--primary mb-2">Establezca una contraseña</p>
          <p>
            Asegúrese de que se cumplan estos requisitos: Mínimo 8 caracteres de largo, mayúsculas y números
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form ref="resetForm" @submit.prevent="handleFormSubmit">
            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Nueva contraseña"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :rules="[validators.required]"
              hide-details="auto"
              class="mb-3"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="confirmPassword"
              outlined
              :type="isConfirmPasswordVisible ? 'text' : 'password'"
              label="Confirmar contraseña"
              placeholder="············"
              hide-details="auto"
              :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              :error-messages="errorMessages.confirmPassword"
              :rules="[validators.required]"
              @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
            ></v-text-field>

            <v-btn block color="primary" class="mt-4" type="submit"> Guardar </v-btn>
          </v-form>
        </v-card-text>

        <v-card-text>
          <v-alert v-show="alertMessage.message" :color="alertMessage.color" dark> {{ alertMessage.message }} </v-alert>
        </v-card-text>

        <!-- back to login -->
        <v-card-actions class="d-flex justify-center align-center">
          <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm">
            <v-icon size="24" color="primary">
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Volver al Inicio de Sesión</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import axios from '@axios'
import { useRouter } from '@core/utils'
import { required } from '@core/utils/validation'
import { mdiChevronLeft, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from 'vue'

export default {
  setup() {
    const resetForm = ref(null)
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const password = ref('')
    const confirmPassword = ref('')
    const buttonLoading = ref(false)
    const alertMessage = ref({})
    const errorMessages = ref('')
    const { router } = useRouter()
    const resetId = ref(router.currentRoute.params.id)

    if (!resetId.value) {
      router.push({ name: 'auth-login' })
    }

    const handleFormSubmit = () => {
      const isFormValid = resetForm.value.validate()

      if (!isFormValid) return

      if (password.value == confirmPassword.value) {
        alertMessage.value = {}
        buttonLoading.value = true

        axios
          .post(`/auth/resetPassword/${resetId.value}`, { newPassword: password.value })
          .then(response => {
            buttonLoading.value = false
            password.value = ''
            confirmPassword.value = ''
            resetForm.value.reset()
            router.push({ name: 'auth-login', params: { resetPassSuccess: true } })
          })
          .catch(error => {
            buttonLoading.value = false
            alertMessage.value = { message: error.response.data.message, color: 'error' }
          })
      } else {
        errorMessages.value = { confirmPassword: ['Revise que las contraseñas sean iguales.'] }
      }
    }

    return {
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,
      resetForm,
      handleFormSubmit,
      errorMessages,
      validators: { required },
      alertMessage,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
